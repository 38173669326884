import React from 'react';
import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import TimelineIcon from '@mui/icons-material/Timeline';
import SearchIcon from '@mui/icons-material/Search';
import CasinoIcon from '@mui/icons-material/Casino';
import InfoIcon from '@mui/icons-material/Info';
import {Search} from '../';
import styles from './SideNav.module.scss';

const SideNav = (props) => {
  const [searching, setSearching] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!searchQuery) {
        return;
      }
      fetch(`https://rap-network-backend-vxnujxkmxa-uc.a.run.app/search?artist=${searchQuery}`, 
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        }
      ).then(res => res.json())
      .then(result => {
        console.log(result);
        setSearchResults(result.artists);
      });
    }, 300)
  }, [searchQuery])

  return (
    <div style={{
      width: "64px",
      height: "100vh",
      position: "absolute",
      left: 0,
      top: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      // borderRight: "1px solid #F7F5FB",
    }}>
      <div className={styles.SearchContainer} style={{width: searching ? 256 : 48, marginLeft: searching ? 200 : 0}}>
        {searching && <Search onChange={e => setSearchQuery(e.target.value)} onBlur={() => setSearching(false)}/>}
        <div onClick={() => setSearching(true)} style={{left: searching ? 208 : 0, top: searching ? 0 : 0, position: "absolute"}} className={styles.IconButton}>
          <SearchIcon/>
        </div>
      </div>
      <div className={styles.IconButton}>
        <TimelineIcon/>
      </div>
      <div className={styles.IconButton}>
        <CasinoIcon/>
      </div>
      <div style={{position: "absolute", bottom: 4}}>
        <div className={styles.IconButton}>
          <InfoIcon/>
        </div>
      </div>
    </div>
  );
};

SideNav.propTypes = {};

SideNav.defaultProps = {};

export default SideNav;
