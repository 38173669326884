import React from 'react';
import ReactDOM from 'react-dom';
import {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import styles from './Graph.module.scss';
import G6 from '@antv/g6';
import CircularProgress from '@mui/material/CircularProgress';
import {useWindowDimensions} from '../../utilities';

// TODO: 
// - Add search bar and search function (show list of results)
// - Add link routing to IDs, clicking on node updates URL
// - Style nodes (show names on hover for less important artists/artists with longer names), show artist picture, etc.
// - Highlight focused/selected node
// - Style edges based on weight
// - Make interface for Bacon search
// - Click on edge to show details for relationship between two artists




const Graph = ({margin}) => {
  const [data, setData] = useState({});
  const [originalData, setOriginalData] = useState({});
  const [edgeMap, setEdgeMap] = useState({});
  const [loading, setLoading] = useState(false);
  const ref = useRef(null);
  const originalDataRef = useRef();
  const dataRef = useRef();
  const edgeMapRef = useRef();
  const screenSizeRef = useRef();
  const constrainBoxRef = useRef();
  const [graph, setGraph] = useState(null);
  // const [selectedNode, setSelectedNode] = useState(null);
  const [fetchedArtists, setFetchedArtists] = useState({});
  const [resizeTimeout, setResizeTimeout] = useState(null);
  // console.log(selectedNode);
  // console.log(data);
  console.log("originalData:");
  console.log(originalData);
  originalDataRef.current = originalData;
  dataRef.current = data;
  edgeMapRef.current = edgeMap;
  const {height, width} = useWindowDimensions();
  screenSizeRef.current = {height: height, width: width};
  console.log(`${height}, ${width}`);
  const padding = {left: 100, right: 100, top: 100, bottom: 100}
  const size = {width: width - margin.left - margin.right, height: height - margin.top - margin.bottom}
  const constrainBox = { x: padding.left, y: padding.top, width: size.width - padding.left - padding.right, height: size.height - padding.top - padding.bottom };
  constrainBoxRef.current = constrainBox;

  const makeGraph = (graphData, maxWeight) => {
    let newGraph = new G6.Graph({
      container: ReactDOM.findDOMNode(ref.current), // String | HTMLElement, required, the id of DOM element or an HTML node
      width: size.width, // Number, required, the width of the graph
      height: size.height, // Number, required, the height of the graph
      // fitView: true,
      layout: {
        type: "gForce",
        center: [constrainBoxRef.current.x + constrainBoxRef.current.width / 2, constrainBoxRef.current.y + constrainBoxRef.current.height / 2],
        // minMovement: 0.1,
        // gpuEnabled: true,
        preventOverlap: true,
        nodeSpacing: 30,
        nodeStrength: 1000,
        maxIteration: 100,
        minMovement: 0.75,
        edgeStrength: (d) => {
          // console.log(d.weight)
          return d.weight / d.maxWeight * 500;
        },
        onLayoutEnd,
        onTick: onLayoutEnd,
      }
    });
    newGraph.on('node:click', (evt) => {
      // console.log(evt);
      // console.log(edgeMap);
      const clickedNode = evt.item.getModel();
      // graph.focusItem(clickedNode.id)
      if (clickedNode.selected) {
        console.log("originalData in onClick:");
        console.log(originalDataRef.current);
        let newData = {...originalDataRef.current};
        let focusedNode = clickedNode.id;
        for (let i=0; i < newData.nodes.length; i++) {
          // console.log(newData.nodes[i])
          if (newData.nodes[i].id === focusedNode) {
            newData.nodes[i].fx = constrainBoxRef.current.x + constrainBoxRef.current.width / 2;
            newData.nodes[i].fy = constrainBoxRef.current.y + constrainBoxRef.current.height / 2;
            newData.nodes[i].focused = true;
          } else {
            newData.nodes[i].fx = null;
            newData.nodes[i].fy = null;             
          }
          newData.nodes[i].selected = false;
          // console.log(newData.nodes[i])
        }

        
        const newLimit = Math.max(2, Math.floor((constrainBoxRef.current.height) / (969 - 200) * (constrainBoxRef.current.width) / (1920 - 200) * 256));
        newData.nodes = pruneNodes(newData.nodes, edgeMapRef.current, focusedNode, newLimit);
        let activeNodes = {};
        for (let node of newData.nodes) {
          activeNodes[node.id] = {};
        }
        newData.edges = pruneEdges(newData.edges, activeNodes, focusedNode);
        
        
        setData(newData);

        return;
      } else if (!clickedNode.fetched && !loading) {
        setLoading(true);
        fetch(`https://rap-network-backend-vxnujxkmxa-uc.a.run.app/artist?id=${clickedNode.id}`, 
          {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            }
          }
        ).then(res => res.json())
        .then(result => {
          setLoading(false);
          console.log(result);
          console.log(clickedNode);
          let newData = {nodes: [], edges: result.edges, time_info: result.time_info};
          let newEdgeMap = {};
          let selectedNode = null;
          let focusedNode = null;

          for (let node of originalDataRef.current.nodes) {
              newEdgeMap[node.id] = {};
              if (node.id === clickedNode.id) {
                node.fetched = true;
              }
              if (node.selected) {
                selectedNode = node.id;
              }
              if (node.focused) {
                focusedNode = node.id;
              }
              newData.nodes.push(node);
          }

          for (let node of result.nodes) {
            if (!(node.id in newEdgeMap)) {
              newEdgeMap[node.id] = {};
              newData.nodes.push(node);
            }
          }

          for (let edge of result.edges) {
            newEdgeMap[edge.source][edge.target] = edge.weight;
            newEdgeMap[edge.target][edge.source] = edge.weight;
          }

          for (let edge of originalDataRef.current.edges) {
            if (!newEdgeMap[edge.source][edge.target]) {
              newEdgeMap[edge.source][edge.target] = edge.weight;
              newEdgeMap[edge.target][edge.source] = edge.weight;
              newData.edges.push(edge);
            }
          }


          let filteredData = {};

          if (selectedNode) {
            filteredData = {
              nodes: newData.nodes.filter(node => node.id in newEdgeMap[selectedNode] || node.id === selectedNode),
              edges: newData.edges.filter(edge => edge.target === selectedNode || edge.source === selectedNode),
            }
            console.log("filteredData:")
            console.log(filteredData);
            
          } else {
            filteredData = {...newData};
          }
          const newLimit = Math.max(2, Math.floor((constrainBoxRef.current.height) / (969 - 200) * (constrainBoxRef.current.width) / (1920 - 200) * 256));
          filteredData.nodes = pruneNodes(filteredData.nodes, newEdgeMap, selectedNode | focusedNode, newLimit);
          let activeNodes = {};
          for (let node of filteredData.nodes) {
            activeNodes[node.id] = {};
          }
          filteredData.edges = pruneEdges(filteredData.edges, activeNodes, selectedNode | focusedNode);

          console.log("newData:")
          console.log(newData);
          setData(filteredData);
          setOriginalData(newData);
          setEdgeMap(newEdgeMap);


          
        })
      }

      let newData = {
        nodes: originalDataRef.current.nodes.filter(node => node.id in edgeMapRef.current[clickedNode.id] || node.id === clickedNode.id),
        edges: originalDataRef.current.edges.filter(edge => edge.target === clickedNode.id || edge.source === clickedNode.id),
      }
      for (let i=0; i < newData.nodes.length; i++) {
        // console.log(newData.nodes[i])
        if (newData.nodes[i].id === clickedNode.id) {
          newData.nodes[i].fx = constrainBoxRef.current.x + constrainBoxRef.current.width / 2;
          newData.nodes[i].fy = constrainBoxRef.current.y + constrainBoxRef.current.height / 2;
          newData.nodes[i].selected = true;
        } else {
          newData.nodes[i].fx = null;
          newData.nodes[i].fy = null;
          newData.nodes[i].selected = false;
          newData.nodes[i].focused = false;
        }
        // console.log(newData.nodes[i])
      }
      // console.log(newData);
      setData(newData);

    })
    newGraph.data(graphData);
    newGraph.render();
    // let focusedNode = null;
    // let selectedNode = null;
    // for (let node of graphData.nodes) {
    //   if (node.selected) {
    //     selectedNode = node.id;
    //   } else if (node.focused) {
    //     focusedNode = node.id;
    //   }
    // }
    // if (selectedNode) {
    //   newGraph.focusItem(selectedNode);
    // } else if (focusedNode) {
    //   newGraph.focusItem(focusedNode);
    // }
    // newGraph.fitCenter();
    // newGraph.fitView();
    setGraph(newGraph);
  }

  const onLayoutEnd = () => {
    let minx = 99999999;
    let maxx = -99999999;
    let miny = 99999999;
    let maxy = -99999999;
    dataRef.current.nodes.forEach((node) => {
      if (minx > node.x) {
        minx = node.x;
      }
      if (maxx < node.x) {
        maxx = node.x;
      }
      if (miny > node.y) {
        miny = node.y;
      }
      if (maxy < node.y) {
        maxy = node.y;
      }

    });
    const scalex = Math.min(2, (constrainBoxRef.current.width) / (maxx - minx));
    const scaley = Math.min(2, (constrainBoxRef.current.height) / (maxy - miny));
    // console.log(constrainBox);
    // console.log(`${maxx}, ${minx}, ${maxy}, ${miny}`)
    // console.log(`${scalex}, ${scaley}`)
    dataRef.current.nodes.forEach((node) => {
      let deltaX = (node.x - minx) * scalex + constrainBoxRef.current.x - node.x;
      let deltaY = (node.y - miny) * scaley + constrainBoxRef.current.y - node.y;
      node.x += deltaX;
      node.y += deltaY;    
      });
  };

  const sortByImportance = (nodes, focusedNode, currentEdgeMap) => {
    nodes.sort((a, b) => {
      if (a.id == focusedNode) {
        return -1;
      }
      if (b.id == focusedNode) {
        return 1;
      }
      let aToFocused = currentEdgeMap[focusedNode][a.id];
      let bToFocused = currentEdgeMap[focusedNode][b.id];
      if (aToFocused && !bToFocused) {
        return -1;
      }
      if (bToFocused && !aToFocused) {
        return 1;
      }
      if (aToFocused === bToFocused) {
        return Object.keys(currentEdgeMap[b.id]).length - Object.keys(currentEdgeMap[a.id]).length;
      }

      return bToFocused - aToFocused;
    })
  }

  const pruneNodes = (nodes, currentEdgeMap, focusedNode, limit) => {
    console.log(focusedNode);
    sortByImportance(nodes, focusedNode, currentEdgeMap);
    if (nodes.length > limit) {
      
      console.log(nodes);
      return nodes.slice(0, limit);
    }
    return nodes;
  }

  const pruneEdges = (edges, activeNodes, focusedNode) => {
    let newEdges = []
    for (let edge of edges) {
      if (edge.source in activeNodes && edge.target in activeNodes) {
        
        newEdges.push(edge);
      }              
    }
    return newEdges;

  }

  useEffect(() => {
    if (!graph) {
      return;
    }
    clearTimeout(resizeTimeout);
    graph.destroy();
    setResizeTimeout(setTimeout(() => {

      const newLimit = Math.max(2, Math.floor((constrainBox.height) / (969 - 200) * (constrainBox.width) / (1920 - 200) * 256));
      console.log(newLimit);
      console.log(edgeMap);
      let selectedNode = null;
      let focusedNode = null;
      for (let node of data.nodes) {
        if (node.selected) {
          selectedNode = node.id;
        }
        if (node.focused) {
          focusedNode = node.id;
        }
      }
      console.log(selectedNode);
      console.log(focusedNode);
      let newData = {...originalData};

      let filteredData = {};

      if (selectedNode) {
        filteredData = {
          nodes: newData.nodes.filter(node => node.id in edgeMap[selectedNode] || node.id === selectedNode),
          edges: newData.edges.filter(edge => edge.target === selectedNode || edge.source === selectedNode),
        }
        console.log("filteredData:")
        console.log(filteredData);
        
      } else {
        filteredData = newData;
      }
      console.log(filteredData);
      filteredData.nodes = pruneNodes(filteredData.nodes, edgeMap, selectedNode | focusedNode, newLimit);
      console.log(filteredData);
      let activeNodes = {};
      for (let node of filteredData.nodes) {
        if (node.selected || node.focused) {
          console.log(node)
          node.fx = constrainBox.x + constrainBox.width / 2;
          node.fy = constrainBox.y + constrainBox.height / 2;
        }
        activeNodes[node.id] = {};
      }
      filteredData.edges = pruneEdges(filteredData.edges, activeNodes, selectedNode | focusedNode);
      
      setGraph(null);
      // graph.changeSize(width, height);
      // graph.clear();
      // setData({});

      setData(filteredData);
    }, 250))
    


    
  }, [constrainBox.width, constrainBox.height, constrainBox.x, constrainBox.y])
    
  useEffect(() => {
    if (!data.nodes || !data.edges) {
      return;
    }
    let graphData = {...data}
    console.log(graphData);
    // graphData.nodes[0].fx = window.innerWidth / 2;
    // graphData.nodes[0].fy = window.innerHeight / 2;

    graphData.edges.sort((a, b) => b.weight - a.weight)
    let maxWeight = graphData.edges[0].weight;


    console.log(maxWeight);

    for (let edge of graphData.edges) {
      edge.maxWeight = maxWeight;
    }
    if (!graph) {
      console.log("Making new graph")
      makeGraph(graphData, maxWeight);
    } else if (graph.destroyed) {
      console.log("graph is destroyed still")
    } else {
      console.log(graph);
      // console.log(graphData);
      graph.changeData(graphData);
      // graph.refresh();

      graph.layout();
      // let focusedNode = null;
      // let selectedNode = null;
      // for (let node of graphData.nodes) {
      //   if (node.selected) {
      //     selectedNode = node.id;
      //   } else if (node.focused) {
      //     focusedNode = node.id;
      //   }
      // }
      // if (selectedNode) {
      //   graph.focusItem(selectedNode);
      // } else if (focusedNode) {
      //   graph.focusItem(focusedNode);
      // }
      // graph.fitCenter();
      // graph.fitView();
      // console.log('fitView')

    }
    
    
  }, [data, graph])

  useEffect(() => {
    let artist = "Rich%20Brian";
    setLoading(true);
    fetch(`https://rap-network-backend-vxnujxkmxa-uc.a.run.app/lookup?artist=${artist}`, 
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      }
    ).then(res => res.json())
    .then(result => {
      setLoading(false);
      console.log(result);
      // setFetchedArtists({[result.nodes[0].id]: true, ...fetchedArtists})
      result.nodes[0].fx = constrainBox.x + constrainBox.width / 2;
      result.nodes[0].fy = constrainBox.y + constrainBox.height / 2;
      result.nodes[0].focused = true;
      result.nodes[0].fetched = true;
      let focusedNode = result.nodes[0].id;
      // setData(result)
      setOriginalData(result);
      let newEdgeMap = {};
      for (let node of result.nodes) {
        newEdgeMap[node.id] = {};
      }
      for (let edge of result.edges) {
        newEdgeMap[edge.source][edge.target] = edge.weight;
        newEdgeMap[edge.target][edge.source] = edge.weight;
      }

      let filteredData = {};
      const newLimit = Math.max(2, Math.floor((constrainBox.height) / (969 - 200) * (constrainBox.width) / (1920 - 200) * 256))
      console.log(`newLimit: ${newLimit}`);
      filteredData.nodes = pruneNodes(result.nodes, newEdgeMap, focusedNode, newLimit);
      let activeNodes = {};
      for (let node of filteredData.nodes) {
        activeNodes[node.id] = {};
      }
      filteredData.edges = pruneEdges(result.edges, activeNodes, focusedNode);
      setData(filteredData);

      setEdgeMap(newEdgeMap);


      
    })
  }, [])

  return (
    <>
      <div style={{
        position: "relative",
        display: "flex",
        marginLeft: `${margin.left}px`,
        marginRight: `${margin.right}px`,
        marginTop: `${margin.top}px`,
        marginBottom: `${margin.bottom}px`
      }} ref={ref}>

      </div>
      {loading && <div style={{
          marginLeft: `${margin.left}px`,
          marginRight: `${margin.right}px`,
          width: `calc(100% - ${margin.left}px - ${margin.right}px)`,
          height: '100vh',
      }} className={styles.Loading}>
        <CircularProgress/>
        Analysing artist...
      </div>}
    </>
  );
};

Graph.propTypes = {};

Graph.defaultProps = {};

export default Graph;
