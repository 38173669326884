import React from 'react';
import PropTypes from 'prop-types';
import styles from './Search.module.scss';

const Search = ({onBlur, onChange}) => {
  return (
    <input
      className = {styles.Search}
      type="text"
      id="header-search"
      placeholder="Search for an artist"
      name="s" 
      autoComplete="off"
      spellCheck="false"
      // onFocus={() => setFocus(true)}
      autoFocus
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

Search.propTypes = {};

Search.defaultProps = {};

export default Search;
