import React from 'react';
import PropTypes from 'prop-types';
import styles from './SearchResultsPage.module.scss';

const SearchResultsPage = (props) => {
  return (
    <div></div>
  );
};

SearchResultsPage.propTypes = {};

SearchResultsPage.defaultProps = {};

export default SearchResultsPage;
