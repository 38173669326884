import React from 'react';
import PropTypes from 'prop-types';
import {Graph, SideNav} from '../../components';
import styles from './GraphPage.module.scss';

const GraphPage = (props) => {
  return (
    <div style={{position: "relative"}}>
      
      <Graph margin={{left: 64, right: 0, top: 0, bottom: 0}}/>
      <SideNav/>
    </div>
  );
};

GraphPage.propTypes = {};

GraphPage.defaultProps = {};

export default GraphPage;
