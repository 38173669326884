import logo from './logo.svg';
import {GraphPage, SearchResultsPage} from './pages';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import './App.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/search" element={<SearchResultsPage/>}/>
        <Route path="/" element={<GraphPage/>}/>
      </Routes>
    </Router>
  );
}

export default App;
